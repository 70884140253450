import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";

import "../css/bootstrap.min.css";
import "../css/main.less";

const AdvancedStreamPlayer = (props) => {
  return (
    <div className={"whrb advanced-player"}>
      <section className="main-content">
        <h1>
          <StaticImage
            title="WHRB 95.3FM - Harvard Radio Broadcasting"
            alt="WHRB Logo"
            src="../images/whrb_logo.svg"
            placeholder="none"
          />
        </h1>
        <div>{props.children}</div>
      </section>
    </div>
  );
};

export default AdvancedStreamPlayer;
