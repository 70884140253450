import * as React from "react";

import DefaultLayout from "./default";
import AdvancedStreamPlayer from "./advancedStreamPlayer";
import GuideEmbed from "./guideEmbed";

const Layout: React.FC<any> = ({ children, pageContext }) => {
  if (pageContext.layout === "advanced-stream-player") {
    return <AdvancedStreamPlayer>{children}</AdvancedStreamPlayer>;
  } else if (pageContext.layout === "current-guide") {
    return <GuideEmbed>{children}</GuideEmbed>;
  }
  return <DefaultLayout>{children}</DefaultLayout>;
};

export default Layout;
