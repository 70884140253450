import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useQuery } from "@tanstack/react-query";
import AudioPlayer from "../components/audioPlayer";
import HeaderNavigation from "../components/headerNavigation";

import "../css/bootstrap.min.css";
import "../css/main.less";

const DefaultLayout: React.FC<any> = (props) => {
  const handleStreamClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    open(
      "/advanced-stream-player",
      "advancedStreamPlayer",
      "popup,width=500,height=300",
    );
  };

  const { data: mixlrData } = useQuery(
    ["mixlr"],
    () =>
      fetch(`https://api.mixlr.com/users/whrb?c=${Date.now()}`).then((res) =>
        res.json(),
      ),
    { refetchInterval: 60000 },
  );

  return (
    <div className={"whrb"}>
      <header id="header" className="container-fluid">
        <section className="header-top">
          <div className="nav-top">
            <a
              href="/advanced-stream-player"
              onClick={handleStreamClick}
              className="nav-tab tab-inverse pull-right"
            >
              STREAM <i className="fa fa-share-square-o"></i>
            </a>
            <Link className="nav-tab tab-inverse pull-right" to="/support">
              SUPPORT WHRB
            </Link>
            <Link className="nav-tab tab-inverse pull-right" to="/advertise">
              ADVERTISE
            </Link>
            <div className="social-icons">
              <div
                id="ccm-block-social-links143699"
                className="ccm-block-social-links"
              >
                <ul className="list-inline">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/pages/WHRB-953FM/369941405267"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://twitter.com/whrb">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/whrb95.3/"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {mixlrData?.is_live && (
              <a
                href="https://mixlr.com/whrb"
                target="_blank"
                className="nav-tab tab-inverse pull-right"
              >
                LIVE ALTERNATE STREAM <i className="fa fa-volume-up"></i>
              </a>
            )}
          </div>
        </section>
        <section className="header-main">
          <div className="logo">
            <h1>
              <Link className="brand" to="/">
                <StaticImage
                  title="WHRB 95.3FM - Harvard Radio Broadcasting"
                  alt="WHRB Logo"
                  src="../images/whrb_logo.svg"
                  placeholder="none"
                />
              </Link>
            </h1>
          </div>
          <div className="audioPlayer">
            <AudioPlayer />
          </div>
          <HeaderNavigation />
        </section>
      </header>
      {props.children}
      <footer id="whrb-footer" className="container-fluid">
        <section className="footer-nav-area">
          <div>
            <h4> © {new Date().getFullYear()} WHRB</h4>
            <div className="ccm-custom-style-container ccm-custom-style-main-352 ccm-block-custom-template-semantic">
              <ul className="ccm-block-page-list-pages">
                <li className="ccm-block-page-list-page-entry">
                  <Link to="/contact">Contact</Link>
                </li>
                <li className="ccm-block-page-list-page-entry">
                  <Link to="/advertise">Advertise</Link>
                </li>
                <li className="ccm-block-page-list-page-entry">
                  <Link to="/support">Support</Link>
                </li>
                <li className="ccm-block-page-list-page-entry">
                  <Link to="/archive">Archive</Link>
                </li>
                <li className="ccm-block-page-list-page-entry">
                  <Link to="/about">About</Link>
                </li>
                <li className="ccm-block-page-list-page-entry">
                  <Link to="/memorial">David Elliott Memorial</Link>
                </li>
              </ul>
            </div>
            <div id="HTMLBlock155866" className="HTMLBlock">
              <a
                href="https://publicfiles.fcc.gov/fm-profile/whrb"
                target="_blank"
              >
                FCC Public File
              </a>
              <p>
                <em>
                  Persons with disabilities who need assistance with issues
                  relating to the content of this station’s FCC public
                  inspection file should contact the WHRB President at{" "}
                  <a href="mailto:president@whrb.org">president@whrb.org</a>.
                </em>
              </p>
            </div>
          </div>
          <div>
            <div className="ccm-custom-style-container ccm-custom-style-main-192055">
              <h4>FOLLOW</h4>
              <ul>
                {" "}
                <li>
                  <a href="https://twitter.com/whrb">Twitter</a>
                </li>
                <li>
                  <a href="https://www.facebook.com/pages/WHRB-953FM/369941405267">
                    Facebook
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h4> Departments</h4>
            <ul className="ccm-block-page-list-pages">
              <li className="ccm-block-page-list-page-entry">
                <Link to="/departments/classical">Classical Music</Link>
              </li>
              <li className="ccm-block-page-list-page-entry">
                <Link to="/departments/jazz">The Jazz Spectrum</Link>
              </li>
              <li className="ccm-block-page-list-page-entry">
                <Link to="/departments/sports">WHRB Sports</Link>
              </li>
              <li className="ccm-block-page-list-page-entry">
                <Link to="/departments/recordhospital">Record Hospital</Link>
              </li>
              <li className="ccm-block-page-list-page-entry">
                <Link to="/departments/tds">The Darker Side</Link>
              </li>
              <li className="ccm-block-page-list-page-entry">
                <Link to="/departments/blues">The Blues Hangover</Link>
              </li>
              <li className="ccm-block-page-list-page-entry">
                <Link to="/departments/hillbilly-at-harvard">
                  Hillbilly at Harvard
                </Link>
              </li>
              <li className="ccm-block-page-list-page-entry">
                <Link to="/departments/news">WHRB News</Link>
              </li>
              <li className="ccm-block-page-list-page-entry">
                <Link to="/departments/bsides">B-Sides</Link>
              </li>
            </ul>
          </div>
          <div>
            <h4>Special Programs</h4>
            <ul className="ccm-block-page-list-pages">
              <li className="ccm-block-page-list-page-entry">
                <Link to="/departments/classical/metropolitan-opera">
                  Metropolitan Opera Broadcasts 2022-23
                </Link>
              </li>
              <li className="ccm-block-page-list-page-entry">
                <Link to="/programming/special-programs/historic-performances">
                  Historic Performances
                </Link>
              </li>
              <li className="ccm-block-page-list-page-entry">
                <Link to="/programming/special-programs/sunday-night-opera">
                  Sunday Night at the Opera
                </Link>
              </li>
              <li className="ccm-block-page-list-page-entry">
                <Link to="/programming/special-programs/orchestra-broadcasts">
                  Beaux Arts Trio
                </Link>
              </li>
              <li className="ccm-block-page-list-page-entry">
                <Link to="/programming/special-programs/memorial-church-service">
                  Memorial Church Service
                </Link>
              </li>
              <li className="ccm-block-page-list-page-entry">
                <Link to="/programming/special-programs/whrb-orgies">
                  WHRB Orgies®
                </Link>
              </li>
            </ul>
          </div>
        </section>
      </footer>
    </div>
  );
};

export default DefaultLayout;
