import * as React from "react";
import { useState, useRef } from "react";
import { useQuery } from "@tanstack/react-query";

const AudioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const { data: songData } = useQuery(
    ["nowplaying"],
    () =>
      fetch("https://whrb-api.herokuapp.com/nowplaying").then((res) =>
        res.json(),
      ),
    { refetchInterval: 15000 },
  );

  const { data: scheduleData } = useQuery(
    ["schedule"],
    () => {
      const now = new Date();
      const timestamp = now.getTime();
      return fetch(
        "https://whrb-api.herokuapp.com/schedule?t=" + timestamp,
      ).then((res) => res.json());
    },
    { refetchInterval: 60000 },
  );

  let song = songData?.SongName ?? "";
  if (songData?.ComposerName) {
    song = songData.ComposerName + ": " + song;
  }

  let show = "";
  if (scheduleData && Array.isArray(scheduleData)) {
    show = scheduleData[0].title;
  }

  const startAudio = () => {
    if (audioRef.current) {
      audioRef.current.load();
      audioRef.current
        .play()
        .then(() => {
          setIsPlaying(true);
          setIsStarting(false);
        })
        .catch((err) => {
          setIsStarting(false);
          console.log(err);
        });

      setIsStarting(true);
    }
  };

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }

    setIsPlaying(false);
    setIsStarting(false);
  };

  let icon = (
    <div className="jp-play" onClick={startAudio}>
      <span className="fa-stack fa-lg">
        <i className="fa fa-circle fa-stack-2x"></i>
        <i className="fa fa-play fa-stack-1x fa-inverse"></i>
      </span>
    </div>
  );

  if (isStarting) {
    icon = (
      <div className="jp-play" onClick={pauseAudio}>
        <span className="fa-stack fa-lg">
          <i className="fa fa-circle fa-stack-2x"></i>
          <i className="fa fa-circle-o-notch fa-spin fa-stack-1x fa-inverse"></i>
        </span>
      </div>
    );
  } else if (isPlaying) {
    icon = (
      <div className="jp-pause" onClick={pauseAudio}>
        <span className="fa-stack fa-lg">
          <i className="fa fa-circle fa-stack-2x"></i>
          <i className="fa fa-stop fa-stack-1x fa-inverse"></i>
        </span>
      </div>
    );
  }

  return (
    <div className="stream-player">
      <div className="stream-player-container">
        <audio ref={audioRef} preload="none">
          <source src="https://stream.whrb.org/whrb-he-aac" type="audio/mpeg" />
          <source src="https://stream.whrb.org/whrb-mp3" type="audio/mpeg" />
        </audio>
        {icon}
        <div className="listen-panel">
          <div>
            <strong>LISTEN</strong>
          </div>
          <div className="current-department" style={{ display: "block" }}>
            {show}
          </div>
          {show !== "Hillbilly at Harvard" && (
            <div className="now-playing" style={{ display: "block" }}>
              {song}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
